import React from 'react'
import PropTypes from 'prop-types'
import AvatarEditor from 'react-avatar-editor'

class PosterEditor extends React.Component {
    static propTypes = {
      imgName: PropTypes.string,
      file: PropTypes.string,
      onSave: PropTypes.func,
    }
  
    onClickSave = async () => {
      if (this.editor) {
        const canvasScaled = await this.editor.getImageScaledToCanvas().toDataURL()
        this.props.onSave(this.props.imgName, canvasScaled)
      }
    }
  
    setEditorRef = (editor) => this.editor = editor
  
    render() {
      return (
        <React.Fragment>
          <AvatarEditor
            image={this.props.file}
            ref={this.setEditorRef}
            width={this.props.width}
            height={this.props.height}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1}
            rotate={0}
          />
          <button className="btn btn-success helsinki w-100" onClick={this.onClickSave}>ajuster</button>
        </React.Fragment>
      )
    }
}

export default PosterEditor