import React, { useRef, useState } from 'react'
import { Col, Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap'
import styled from '@emotion/styled'
import PosterEditor from './PosterEditor'
import { getTMDBSuggestions } from '../apis/search'
import { parseVideoUrl, isUrlCorrect } from '../utils'
import { addVideo } from '../apis/video'
import { navigate } from 'gatsby'

const ModalAddVideo = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  
  const imageRef = useRef();

  const [url, setUrl] = useState('')
  const [source, setSource] = useState('')
  const [audio, setAudio] = useState('fr')
  const [subtitles, setSubtitles] = useState('none')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [imageInput, setImageInput] = useState('')
  const [imageCropped, setImageCropped] = useState('')
  const [videoType, setVideoType] = useState('movie')

  const [selectedMovie, setSelectedMovie] = useState('')
  const [query, setQuery] = useState([])
  const [suggestions, setSuggestions] = useState([])

  const [season, setSeason] = useState('')
  const [seasonNumber, setSeasonNumber] = useState('')


  const handleSearchSuggestions = async query => {
    const result = await getTMDBSuggestions(query, videoType)
    setSuggestions(result)
  }

  const handleSugggestionClick = async s => {
    setSelectedMovie(s)
    setQuery(videoType === 'tv' ? s.name : s.title)
    setSuggestions([])
  }

  const handleSearchChange = (e) => { 
    const input = e.target.value
    setQuery(input)
    if (input && input.length > 1){
      handleSearchSuggestions(input)
    } else if (!input || input.length < 1){
      setSuggestions([])
    }
  }

  const handleVideoUrl = e => {
    const pasted = e.target.value
    setUrl(pasted)
    const parsed = parseVideoUrl(pasted)
    const src = isUrlCorrect(pasted)
    if (parsed) {
      setUrl(parsed)
      setSource(src)
    } else {
      console.log(pasted)
    }
  }


  const handleCropImage = (name, file) => {
    setImageInput(file)
    setImageCropped(true)
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    setImageInput(file)
    setImageCropped(false)
  }

  const toggle = () => setIsOpen(!isOpen);

  const handleSubmit = async e => {
    e.preventDefault()
    if(!selectedMovie) return
    if(!source) return
    setIsLoading(true)
    const isTvShow = videoType === 'tv'
    const data = {
      movie: selectedMovie,
      is_tv_show: isTvShow,
      video: {
        url: url,
        source: source,
        audio: audio,
        subtitles: subtitles,

        title: title.length ? title : null,
        description: description.length ? description : null,
        image: imageInput,

        group_name: season.length ? season : null,
        group_number: seasonNumber.length ? seasonNumber : null
      }
    }
    try {
      const response = await addVideo(data)
      setIsLoading(false)
      // navigate(`/video/${response.video.id}`)
      alert("Ajouté avec succès (et en cours de révision :)")
    } catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  const renderSuggestions = suggestions.map(s => {
    return (
      <DropdownItem onClick={() => handleSugggestionClick(s)} key={s.id}>
        <img height="100" width="67" src={`https://image.tmdb.org/t/p/w200/${s.poster_path}`}/>
        {videoType === 'movie' ?
          <span className="ml-1 text-break mw-100">{s.title} ({s.original_title}) - ({s.release_date && s.release_date.toString().slice(0,4)})</span>
          : <span className="ml-1 text-break mw-100">{s.name} ({s.original_name}) - ({s.first_air_date && s.first_air_date.toString().slice(0,4)})</span>
        }
      </DropdownItem>
    )
  })

  return (
    <div>
      <div onClick={toggle}>
        {props.children}
      </div>
        <Modal centered={true} isOpen={isOpen} toggle={toggle} size='md'>
          <div>
            <ModalHeader toggle={toggle}>Ajouter video</ModalHeader>
            <Form onSubmit={handleSubmit}>
              <ModalBody>
              <FormGroup>
                <Label for="selectType">Type de video</Label>
                <Input type="select" name="select" id="selectType" value={videoType} onChange={(e) => {setVideoType(e.target.value);setSelectedMovie('');setSuggestions([])} }>
                  <option value="movie">Film</option>
                  <option value="tv">Épisode d'une série</option>
                </Input>
              </FormGroup>
                <FormGroup>
                  <Dropdown isOpen={!(!suggestions.length)} toggle={() => {}} className="w-100">
                    <DropdownToggle tag="div" className="w-100 ">
                      <Label for="film">Film</Label>
                      <Input type="search" aria-label="Search"  name="film" id="film" placeholder="Chercher titre du film" value={query} onChange={handleSearchChange} />
                    </DropdownToggle>
                    {suggestions.length 
                    ? <DropdownMenu className="font-family-native overflow-auto mw-100  text-break" style={{maxHeight:200}} left>
                        {renderSuggestions}
                      </DropdownMenu>
                    : ''
                    }
                  </Dropdown>
                </FormGroup>
                <FormGroup>
                  <Label for="video">Lien de la vidéo</Label>
                  <Input onChange={handleVideoUrl} value={url} type="url" name="video" id="video" placeholder="URL (youtube, archive, dailymotion ou vimeo)" />
                </FormGroup>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="audio">Audio</Label>
                      <Input type="select" name="audio" id="audio" value={audio} onChange={(e) => setAudio(e.target.value)} > 
                        <option value="silent">Muet</option>
                        <option value="fr">Français</option>
                        <option value="es">Espagnol</option>
                        <option value="lat">Espagnol (Amérique du Sud)</option>
                        <option value="en">Anglais</option>
                        <option value="it">Italien</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="subtitles">Sous-Titres</Label>
                      <Input type="select" name="subtitles" id="subtitles" value={subtitles} onChange={(e) => setSubtitles(e.target.value)} >
                        <option value="none">Pas de sous-titres</option>
                        <option value="fr">Français</option>
                        <option value="es">Espagnol</option>
                        <option value="en">Anglais</option>
                        <option value="it">Italien</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  { videoType === "tv" &&
                    <>
                      <FormGroup>
                        <Label for="season">Nom du groupe</Label>
                        <Input onChange={(e) => setSeason(e.target.value)} value={season}  type="text" name="season" id="season" placeholder="Ex: 'Saison 1' ou 'Compilation'" />
                        <div className="small">
                          <span className="">Les plus utilisés: </span> 
                          <span className="text-sm text-secondary cursor-pointer" onClick={() => setSeason("Saison 1")}>Saison 1</span>
                          <span className="text-sm text-secondary cursor-pointer" onClick={() => setSeason("Compilación")}> - Compilación</span>

                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label for="seasonNumber">Numéro </Label>
                        <Input onChange={(e) => setSeasonNumber(e.target.value)} value={seasonNumber}  type="number" name="seasonNumber" id="seasonNumber" placeholder="1, 2, 3, etc.." />
                      </FormGroup>
                    </>
                  }
                </Row>
                <h6 className="cursor-pointer" onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
                  Optionnel
                  { isCollapseOpen 
                    ?   <svg className="font-weight-bold" className="ml-1" xmlns="http://www.w3.org/2000/svg" height="15" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    :    <svg className="font-weight-bold" className="ml-1" xmlns="http://www.w3.org/2000/svg" height="15" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                        </svg>
                  }
            
              
                </h6>
                <Collapse isOpen={isCollapseOpen}>
                  <FormGroup>
                    <Label for="title">Titre</Label>
                    <Input onChange={(e) => setTitle(e.target.value)} value={title} type="text" name="title" id="title" placeholder="Titre de la vidéo" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input onChange={(e) => setDescription(e.target.value)} value={description}  type="textarea" name="description" id="description" placeholder="Description ou synopsis" />
                  </FormGroup>
                  <FormGroup className="d-none">
                    <Label for="poster">Image</Label> <br />
                    <input className={imageCropped && "text-success"} type="file" ref={imageRef} name="image" onChange={handleImageChange} />
                    { imageInput && !imageCropped &&
                      <PosterEditor file={imageInput} imgName="imageInput" height={144*1.5} width={256*1.5} onSave={handleCropImage}/>
                    }
                  </FormGroup>

                </Collapse>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" className="btn btn-primary btn-lg mb-0">
                  {isLoading && <Spinner size="sm"/> || 'Ajouter'}
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </Modal>
    </div>
  )
}

export default ModalAddVideo

const Button = styled.button`
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
  font-weight: bold;
  background: #99c432;		
  background-color: #2557b7;
  &:hover{
    background: #346bc2;
  }
`