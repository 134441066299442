import {url, getToken} from '../api'

export const addVideo = async data => {
    const token = await getToken()
    const headers = {'content-type': 'application/json'}
    if (token !== null) {
        headers['Authorization'] = `Token ${token}`
    }
    const response = await fetch(`${url}/videos/add`, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({
            'data': data,
        }),
    })

    if (response.ok) {
        const result = await response.json()
        return result
    }

    const errMessage = await response.json()
    // Jsonify and parse later because error.messages will apply .toString()
    throw new Error(JSON.stringify(errMessage))       
}