const TMDB_API_KEY = process.env.GATSBY_TMDB_API_KEY

export const getTMDBSuggestions = async (query, type="movie") => {
  try {
    const response = await fetch(`https://api.themoviedb.org/3/search/${type}?include_adult=false&page=1&query=${query}&language=fr-FR&api_key=${TMDB_API_KEY}`)
    const result = await response.json()

    return result.results
  } catch (err) {
    console.log(err)
  }
}
  